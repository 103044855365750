<template>
  <div id="launchpad" class="overflow-hidden">
    <b-alert variant="secondary" show>
      <b-row>
        <b-col cols="auto" class="my-auto">
          <netvs-icon icon="launchpad_planet" size="2x"/>
        </b-col>
        <b-col class="h-100 my-auto">
          <p class="my-auto">{{ $t('views.launchpad.info') }}</p>
        </b-col>
      </b-row>
    </b-alert>
    <b-row style="height: 70vh">
      <b-col class="text-center mt-auto mb-auto">
        <netvs-icon id="rocket" style="font-size: 15vh" :icon="flame ? 'launchpad_active' : 'launchpad'"
                    @click="flame = true" :class="{launch: flame}"/>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import BCDService from '@/api-services.gen/nd.bcd'
import OUService from '@/api-services.gen/org.unit'
import GroupService from '@/api-services.gen/cntl.group'
import FQDNService from '@/api-services.gen/dns.fqdn'

export default {
  name: 'LaunchPad',
  data() {
    return {
      flame: false
    }
  },
  async mounted() {
    const component = this.$route.params.component
    const payload = this.$route.params.pathMatch
    let variant = this.$route.hash
    if (!variant || variant === '' || variant === '#') {
      variant = null
    } else {
      variant = variant.substring(1)
    }
    const actions = {
      'nd.bcd': async () => {
        const res = (await BCDService.list(this.$store.state, {gpk: payload})).data[0]
        window.console.debug(res[0])
        if (this.checkRes(res)) {
          if (variant) {
            window.console.debug('Pushing to', `/${variant}/bcds/` + res[0].name)
            await this.$router.push(`/${variant}/bcds/` + res[0].name)
          } else {
            await this.$router.push('/dnsvs/bcds/' + res[0].name)
          }
        }
      },
      'nd.module': async () => {
        await this.$router.push('/netdoc/modules/' + payload)
      },
      'org.unit': async () => {
        const res = (await OUService.list(this.$store.state, {gpk: payload})).data[0]
        if (this.checkRes(res)) {
          await this.$router.push('/org/ou/' + res[0].short_name)
        }
      },
      'cntl.group': async () => {
        const res = (await GroupService.list(this.$store.state, {gpk: payload})).data[0]
        if (this.checkRes(res)) {
          await this.$router.push('/cntl/groups/' + encodeURIComponent(res[0].name))
        }
      },
      'dns.fqdn': async () => {
        const res = (await FQDNService.list(this.$store.state, {gpk: payload})).data[0]
        if (this.checkRes(res)) {
          await this.$router.push('/dnsvs/fqdns/' + res[0].value)
        }
      },
      'requests.patch': async () => {
        this.$store.commit('setPatchRequestEditAction', {action: payload, index: -1})
        await this.$router.push('/requests/')
      },
      search: async () => {
      }
    }
    await actions[component]()
  },
  methods: {
    checkRes(res) {
      if (res.length > 0) {
        return true
      }
      this.$router.push('/404')
      return false
    }
  }
}
</script>

<style scoped>
#rocket {
  offset-path: path('M 0 0');
  animation: fadein 2s;
  opacity: 0.3;
}

#rocket.launch {
  offset-path: path('M 0 0 C 500 -500 500 500 0 00 C -500 -500 -500 500 0 0');
  transform: rotate(45deg) translateY(-50px);
  transform-origin: 50% 50%;
  animation: move 2s infinite linear;
}

@keyframes move {
  0% {
    offset-distance: 0%;
  }
  100% {
    offset-distance: 100%;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.3;
  }
}

</style>
